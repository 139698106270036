import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Image } from "react-bootstrap";
import img1 from "./assets/Images/clock1.jpg";
import Carousel from "react-bootstrap/Carousel";

function HpLaptop240() {
  return (
    <Container>
      <Row>
        <Col className="bg-Primary m-2 py-2">
          <Carousel data-bs-theme="dark">
            <Carousel.Item>
              <img className="d-block w-50" src={img1} alt="First slide" />
            </Carousel.Item>

            <Carousel.Item>
              <img className="d-block w-50" src={img1} alt="First slide" />
            </Carousel.Item>

            <Carousel.Item>
              <img className="d-block w-50" src={img1} alt="First slide" />
            </Carousel.Item>
          </Carousel>
        </Col>

        <Col>2</Col>
      </Row>
    </Container>
  );
}

export default HpLaptop240;
