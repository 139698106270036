// Make sure to run npm install @formspree/react
// For more help visit https://formspr.ee/react-help
import React from "react";
import { useForm, ValidationError } from "@formspree/react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import img2 from "./assets/Images/logo512.png";
import Card from "react-bootstrap/Card";
import Table from 'react-bootstrap/Table';

function Contact() {
  const [state, handleSubmit] = useForm("xbjnydjn");
  if (state.succeeded) {
    return <p>Thanks for joining!</p>;
  }
  return (
    <Container className=''>
      <Row>


      <Col className="m-20 py-5">
          <Card.Img
            style={{ width: 400, height: 600, alignItems: "center" }}
            variant="top"
            src={img2}
          />
        </Col>

        
        <Col>
          <form
            onSubmit={handleSubmit}
            style={{
              width: 250,
              height: 500,
              margin: 100,
              padding: 30,
              alignItems: "center",
            }}
            className="bg-primary "
          >
            <label htmlFor="email" className="text-white font-size-large">
              Email Address
            </label>
            <input className="m-2 py-2" id="email" type="email" name="email" />
            <label htmlFor="Name">Name</label>
            <input className="m-2 py-2" id="email" type="text" name="Name" />
            <ValidationError
              prefix="Email"
              field="email"
              errors={state.errors}
            />

            <label htmlFor="Mobile">Mobile No</label>
            <input className="m-2 py-2" id="Mobile" type="text" name="Mobile" />
            <label htmlFor="Address" className="m-1 py-1">
              Address
            </label>
            <textarea className="h-25 w-100" id="message" name="message" />
            <ValidationError
              prefix="Message"
              field="message"
              errors={state.errors}
            />

            <button
              type="submit"
              disabled={state.submitting}
              className="bg-warning text-white rounded-bottom border border-white"
            >
              Submit
            </button>
            <label htmlFor="" className="text-white font-size-large m-2">
              Cash On Delivey 
            </label>

          </form>
        </Col>

        
      </Row>

      <Row>

      <Table striped bordered hover>
      <thead>
        <tr>
          <th>#</th>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Username</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>Mark</td>
          <td>Otto</td>
          <td>@mdo</td>
        </tr>
        <tr>
          <td>2</td>
          <td>Jacob</td>
          <td>Thornton</td>
          <td>@fat</td>
        </tr>
         <tr>
          <td>2</td>
          <td>Jacob</td>
          <td>Thornton</td>
          <td>@fat</td>
        </tr>
         <tr>
          <td>2</td>
          <td>Jacob</td>
          <td>Thornton</td>
          <td>@fat</td>
        </tr>
        
        <tr>
          <td>2</td>
          <td>Jacob</td>
          <td>Thornton</td>
          <td>@fat</td>
        </tr>
         <tr>
          <td>2</td>
          <td>Jacob ask jjs jkakjcs jjbsc jbsc scj,msa </td>
          <td>Thornton m,nsac,m  mas ccaskc  asc as,c as   as</td>
          <td>@fat</td>
        </tr>
        <tr>
          <td>3</td>
          <td colSpan={2}>Larry the Bird</td>
          <td>@twitter</td>
        </tr>
      </tbody>
    </Table>

      </Row>
    </Container>
  );
}

export default Contact;
