import React from "react";



const listings = [
  {
    id: 1,
    description: "This is go product",
    Image: "./clock.jpg",

  },
];

function ListingCard() {
  const listingComponents = listings.map((listing) => (
    <div key={listing.id}>
      <h1>{listing.description}</h1>

      <img src={listing.Image} alt={listing.description} />
    </div>
  ));

  return (
    <div style={{margin: 10, padding: 10, width: 400, height: 800, }}>
      <h1>Free Stuff Page</h1>
      {listingComponents}

    </div>
  );
}

export default ListingCard;
