import React from 'react'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import  img1 from "./assets/Images/clock.jpg"
import  img2 from "./assets/Images/mouse.png"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import img3 from "./assets/Images/chargermobile.jpg"



const Home = () => {
  return (
    <Container className='m-10 py-5'>
      <Row>
    <Card style={{ width: '18rem', margin:30 }}>
    <Card.Img style={{width: 250, height: 200, alignItems:'center'}} variant="top" src={img1} />
      <Card.Body>
        <Card.Title className='text-center'>Card Title</Card.Title>
        <Card.Text className='text-center'>
          Some quick example text to build on the card title and make up th
        </Card.Text> 
        <Button style={{width:100, height:40}}href="/Contact" variant="primary">500</Button>
        <Button className='m-2 py-2 text-center w-25'href="/Contact" variant="primary">Buy</Button>
      </Card.Body>
    </Card>

    <Card style={{ width: '18rem', margin:10 }}>
    <Card.Img style={{width: 250, height: 200, alignItems:'center'}} variant="top" src={img2} />
      <Card.Body>
        <Card.Title className='text-center'>Card Title</Card.Title>
        <Card.Text className='text-center'>
          Some quick example text to build on the card title and make up th
        </Card.Text> 
        <Button style={{width:100, height:40}}href="/About" variant="primary">500</Button>
        <Button className='m-2 py-2 text-center w-25'href="/About" variant="primary">Buy</Button>
      </Card.Body>
    </Card>


    <Card style={{ width: '18rem', margin:10 }}>
    <Card.Img style={{width: 250, height: 200, alignItems:'center'}} variant="top" src={img3} />
      <Card.Body>
        <Card.Title className='text-center'>Card Title</Card.Title>
        <Card.Text className='text-center'>
          Some quick example text to build on the card title and make up th
        </Card.Text> 
        <Button style={{width:100, height:40}}href="/Mobileimg3" variant="primary">500</Button>
        <Button className='m-2 py-2 text-center w-25'href="/Mobileimg3" variant="primary">Buy</Button>
      </Card.Body>
    </Card>

   
    
    
    
   
   
    </Row>
    </Container>


    
  );
}

export default Home;

