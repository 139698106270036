import { Routes, Route } from "react-router-dom";
import Home from "./Home";
import About from "./About";
import Navbartop from "./Navbartop";
import Pricing from "./Pricing";
import ListingCard from "./ListingCard";
import  HpLaptop240 from "./HpLaptop240"
import Watch from "./Watch";
import Contact from "./Contact"
import Mobileimg3 from "./Mobileimg3"

const App = () => {
  return (
    <div className="App">

      <Navbartop />
      
      <Routes>
        <Route path="/" element={<Home />}>
          Home
        </Route>
        <Route path="/About" element={<About />}>
          About
        </Route>

        <Route path="/Pricing" element={<Pricing />}>
          Pricing
        </Route>

        <Route path="/Watch" element={<Watch />}>
          Watch
        </Route>

        <Route path="/Contact" element={<Contact />}>
        Contact
        </Route>

        
        <Route path="/Mobileimg3" element={<Mobileimg3 />}>
        Mobileimg3
        </Route>

        <Route path="/ListingCard" element={<ListingCard />}>
        ListingCard
        </Route>


        
        <Route path="/HpLaptop240" element={<HpLaptop240 />}>
        HpLaptop240
        </Route>
      </Routes>
    </div>
  );
};

export default App; 
